@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
      }
    }

    .scrollbar::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
  
    .scrollbar::-webkit-scrollbar-track {
      background: rgba(141, 101, 253, 0.1);
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      
    }
  
    .scrollbar::-webkit-scrollbar-thumb {
      background: #c4b5fd;
      height: 60px;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      border: 1px solid #f6f7ed;
    }
  
    .scrollbar::-webkit-scrollbar-thumb:hover {
      background: #8D65FD;
    }

  }

* {
  font-family:Arial, Helvetica, sans-serif;
}

html {
  background-color: #d1d5db;
}

h1 {
    color: #8D65FD;
}

